<!-- eslint-disable -->
<template>
  <div class='icon-hamburger'>
    <svg id="menu" xmlns="http://www.w3.org/2000/svg" :fill="color" width="21" height="17" viewBox="0 0 21 17">
      <rect id="Rectangle_353" data-name="Rectangle 353" width="21" height="3" rx="1.5"/>
      <rect id="Rectangle_354" data-name="Rectangle 354" width="21" height="3" rx="1.5" transform="translate(0 7)"/>
      <rect id="Rectangle_355" data-name="Rectangle 355" width="21" height="3" rx="1.5" transform="translate(0 14)"/>
    </svg>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconHamburger',
  props: {
    color: {
      type: String,
      required: false,
      default: '#000',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
